input[type="radio"] {
  display: none;
}

.carousel-card {
  position: absolute;
  width: 60%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform 0.4s ease;
  cursor: pointer;
}

.carousel-container {
  width: 100%;
  height: 400px;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.carousel-cards {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.carousel-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

#item-1:checked ~ .carousel-cards #song-3,
#item-2:checked ~ .carousel-cards #song-1,
#item-3:checked ~ .carousel-cards #song-2 {
  transform: translateX(-40%) scale(0.8) rotateY(30deg);  // Adjusted rotation here
  opacity: 0.4;
  z-index: 0;
}

#item-1:checked ~ .carousel-cards #song-2,
#item-2:checked ~ .carousel-cards #song-3,
#item-3:checked ~ .carousel-cards #song-1 {
  transform: translateX(40%) scale(0.8) rotateY(-30deg);  // Adjusted rotation here
  opacity: 0.4;
  z-index: 0;
}

#item-1:checked ~ .carousel-cards #song-1,
#item-2:checked ~ .carousel-cards #song-2,
#item-3:checked ~ .carousel-cards #song-3 {
  transform: translateX(0) scale(1) rotateY(0);  // Adjusted rotation here
  opacity: 1;
  z-index: 1;

  img {
    box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
  }
}
