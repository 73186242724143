@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./sass/variables/variables.scss";

.App {
  text-align: center;
  background-color: #06011a;
  height: 100vh;
  overflow-x: hidden;
  font-family: Poppins;
}

.body {
  background-color: #06011a;
}

.drawer-toggle ~ .drawer-side {
  grid-column-start: 1;
  grid-row-start: 1;
  display: grid;
  max-height: 100%;
}

.bottom-10 {
  top: 90vh;
}

.hero-body {
  width: 100%;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}

.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  background: transparent;
  border: none;
  outline: none;
}

.primary-button {
  padding: 10px 17px;
  background: rgba(247, 250, 255, 0.1);
  border: 1px solid #f7faff;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.secondary-button {
  padding: 10px 17px;
  background: linear-gradient(
      273.47deg,
      rgba(255, 255, 255, 0) -1.96%,
      #2d1582 104.81%
    ),
    #fd004e;
  border-radius: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none;
}


    .unsubscribe-button {
      padding: 10px 17px;
      background: linear-gradient(
          273.47deg,
          rgba(255, 255, 255, 0) -1.96%,
          #00ffcc 104.81%
        ),
        #00cc66;
      border-radius: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      border: none;
      outline: none;
      transition: background 0.3s ease-in-out;
    }
    
    .unsubscribe-button:hover {
      background: linear-gradient(
          273.47deg,
          rgba(255, 255, 255, 0) -1.96%,
          #00cc99 104.81%
        ),
        #00994d;
    }
    
  
  

label {
  cursor: pointer;
}

.example {
  overflow-y: scroll;
  height: 100%;
  height: 100%;
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*  */

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0var (--swiper-pagination-bullet-horizontal-gap, 4px);
  background: white !important;
}

/*  */
.swiper-button-next,
.swiper-button-prev {
  color: white !important;
}
.textrgbcolor {
  background: linear-gradient(
      290.19deg,
      rgba(255, 255, 255, 0) 26.59%,
      #2d1582 112.22%
    ),
    #fd004e;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.button-background {
  background: linear-gradient(
      273.47deg,
      rgba(255, 255, 255, 0) -1.96%,
      #2d1582 104.81%
    ),
    linear-gradient(0deg, #fd004e, #fd004e), #1b1c2d;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
}

#toast-message {
  background: #1b1c2d;
  border: 1px solid #363636;
  border-radius: 5px;
  color: #fff;
}
.drawer {
  height: 100%;
}

// Speech Bubbles



.speech-bubble-right {
  border: 0.5px solid #ffffff20;
  background-color: #06011a80;
  height: auto;
  padding: 8px 12px;
  margin-bottom: 10px;
  border-radius: 24px;
  border-bottom-right-radius: 0px;
  width: fit-content;
}

.speech-bubble {
  border: 0.5px solid #ffffff50;
  border-radius: 16px;
}
/* Online indicator styling */
.online-indicator::before {
  content: ''; /* This is necessary for the pseudo-element to work */
  display: inline-block;
  margin-right: 5px; /* Adjust as needed */
  height: 10px; /* Size of the dot */
  width: 10px; /* Size of the dot */
  background-color: #00FF00; /* Green color */
  border-radius: 50%; /* Makes it circular */
}

/* Style adjustments for speech bubbles to incorporate online indicator */
// .speech-bubble-left, .speech-bubble-right {
//   position: relative;
//   padding-left: 15px; /* Ensures text doesn't overlap the online indicator */
// }

// .speech-bubble-left .online-indicator::before, .speech-bubble-right .online-indicator::before {
//   position: absolute;
//   left: 0; /* Aligns the dot to the left edge of the speech bubble */
//   top: 50%; /* Centers the dot vertically */
//   transform: translateY(-50%); /* Ensures it's perfectly centered */
// }

.animated-send-btn {
  transition: transform 0.2s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: #4A4B5D;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.animated-send-btn:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.6);
  background: linear-gradient(
    273.47deg,
    rgba(255, 255, 255, 0) -1.96%,
    #2d1582 104.81%
  ),
  #fd004e;
}

/* Subtle pulse effect */
.animated-send-btn::after {
  content: "";
  position: absolute;
  width: 120%;
  height: 120%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 80%);
  border-radius: 50%;
  opacity: 0;
  transform: scale(0.5);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.animated-send-btn:hover::after {
  opacity: 1;
  transform: scale(1.2);
}

.speech-bubble-left, .speech-bubble-right {
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-width: 75%;
  min-width: 20%;
}
