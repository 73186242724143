.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    /* Fix of Webkit flickering */
    z-index: 1;
  }
  
  .swiper {
    width: 100%;

    //  padding-right: 100px;
  
  }
  
  .swiper-slide {
    z-index: 0;
    background-position: center;
    background-size: cover;


  }
  
  .swiper-slide img {
    z-index: 0;
    display: block;
    width: 100%;
    object-fit: contain
      /* Use the calculated imageHeightToFit here */;
    margin: 0 auto;
  }
  
  
  
  .swiper-slide .swiper-slide-visible {
    z-index: -4;
    margin-right: 50px;
    transition-duration: 0ms;
    
    // transform: translate3d(0px, 0px, -450px) rotateX(0deg) rotateY(225deg) scale(1);
  }
