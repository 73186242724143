@import "../variables/variables.scss";

.marque-navbar {
  height: 100px;
  position: fixed;
  top: 0px;
  z-index: 9999;
  width: 100vw;
}

.navbar {
  height: 100px;
  width: 100vw;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  background: #06011a60;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  margin: auto;
  left: 0;
  right: 0;
}

.navbar-active {
  height: 100px;
  width: 100vw;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0px;
  z-index: 9999;
  background: #06011a60;
  box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.15);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  margin: auto;
  left: 0;
  right: 0;
}

.navbar-contents {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.icons {
  height: 30px;
  width: 30px;
}

.nav-links {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 30px;
  list-style: none;
}

.nav-link {
  text-decoration: none;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6) -3.57%,
    rgba(255, 255, 255, 0.8) 47.67%,
    rgba(255, 255, 255, 0.6) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  transition: 0.3s all;
}

.nav-icon {
  display: none;
}

.navbar-link:hover,
a.navbar-item.is-active,
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover {
  background: linear-gradient(
    90deg,
    rgba(245, 6, 6, 0.6) -3.57%,
    rgba(236, 12, 12, 0.8) 47.67%,
    rgba(224, 56, 56, 0.6) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  background-clip: text;
}

.nav-image {
  width: 102px;
}

.navbar-item img {
  max-height: none;
}

.navbar-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6) -3.57%,
    rgba(255, 255, 255, 0.8) 47.67%,
    rgba(255, 255, 255, 0.6) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  background-clip: text;
}

// .nav-item-dark {
//   color: grey;
// }

.navbar-item:hover {
  background: linear-gradient(
    90deg,
    rgba(245, 6, 6, 0.6) -3.57%,
    rgba(236, 12, 12, 0.8) 47.67%,
    rgba(224, 56, 56, 0.6) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  background-clip: text;
}

.nav-icons {
  font-size: 20px;
  color: grey;
}

.nav-icons:hover {
  font-size: 20px;
  color: rgba(253, 0, 78, 1);
}
.hamburger-buttons {
  display: none;
}

@media only screen and (max-width: 820px) {
  .nav-buttons {
    display: none;
  }

  .marque-navbar {
    height: 80px;
    position: fixed;
    top: 0px;
    z-index: 9999;
    width: 100vw;
  }

  .navbar {
    height: 80px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 9999;
    // background: rgba(6, 1, 26, 1);
  }

  .navbar-active {
    height: 50px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 9999;
    // background: rgba(6, 1, 26, 1);
  }

  .nav-image {
    width: 100px;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    // text-align: center;
    align-items: flex-start;
    width: 100%;
    top: 60px;
    left: -100%;
    transition: 0.5s all;
  }

  #mobile-nav-links {
    z-index: 100;
    background: rgba(6, 1, 26, 1);
    box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.15);
    padding: 10px;
    backdrop-filter: blur(10px);
    width: 100vw;
    height: calc(100vh - 50px);
    justify-content: center;
    align-items: center;
  }

  .nav-links.active {
    left: 0;
    padding-bottom: 30px;
    padding-top: 10px;
    display: flex;
    justify-content: center;
  }

  .nav-icon {
    display: flex;
    font-size: 26px;
    color: #fafafa;
    cursor: grab;
  }

  .button-container {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 820px) {
  .marque-navbar {
    height: 80px;
    position: fixed;
    top: 0px;
    z-index: 9999;
    width: 100vw;
  }
  .hamburger-buttons {
    display: flex;
 flex-direction: column;
 align-items: center;

  }
  .footer-icons-set{
margin-top: 10px;
  }

  .navbar {
    height: 80px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    z-index: 9999;
  }

  .navbar-active {
    height: 80px;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    z-index: 9999;
  }

  .nav-icons {
    font-size: 20px;
    color: #ffffff;
  }

  .nav-icon {
    font-size: 24px;
    color: #ffffff;
  }

  .nav-icons:hover {
    font-size: 18px;
    color: #21f2ff;
  }
  .nav-content{
    margin-top: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .navbar {
    height: 80px;
    position: fixed;
    width: 100vw;
    display: flex;
    background: transparent;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    z-index: 9999;
  }
  .nav-logo-image {
    height: 50px;
  }
  .navbar-active {
    height: 80px;
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    z-index: 9999;
    background: #06011a;
  }
  .hamburger-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
  }
  .nav-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.7);
  }

  
}
