.create-section {
  background-image: url("../../assets/images/homepage/Frame2.png");
  background-position: top left;
  background-repeat: no-repeat;
}

.create-header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
}

.project-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.project-number-text {
  padding: 5px 12px;
  gap: 10px;
  width: 28px;
  height: 28px;
  background: #21325b;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.project-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.project-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
}

.create-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.create-main-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 250;
  font-size: 53.4573px;
  line-height: 71px;
  text-transform: capitalize;
  background: linear-gradient(
      307.97deg,
      rgba(255, 255, 255, 0) 9.04%,
      #9e23ff 106.61%
    ),
    #00fdee;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media only screen and (max-width: 480px) {
  .create-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}
