body{
  background-color: #0a0228;
}
.top-section-hero {
  background-image: url("../../assets/images/homepage/back.png");
  background-position: center;
  background-repeat: no-repeat;
}

.hero-body {
  padding: 9rem 120px 5rem;
  width: 100%;
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 50;
  font-size: 48px;
  line-height: 54px;
  text-transform: capitalize;
  color: #ffffff;
}

.subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.subtitle strong {
  color: rgba(253, 0, 78, 1);
}

.transparent-button {
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.lets-do-it-box {
  padding: 43px 28px;
  background: linear-gradient(
      105.9deg,
      rgba(55, 125, 255, 0.15) 7.44%,
      rgba(255, 255, 255, 0) 66.31%
    ),
    #0a0228;
  border-radius: 10px;
}

/* Circular Text */

.scroll-image {
  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -ms-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  -ms-animation-duration: 5s;
  -o-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0);
  }
}
@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(360deg);
  }
  to {
    -moz-transform: rotate(0);
  }
}
@-ms-keyframes rotate {
  from {
    -ms-transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0);
  }
}
@-o-keyframes rotate {
  from {
    -o-transform: rotate(360deg);
  }
  to {
    -o-transform: rotate(0);
  }
}
@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}

@media only screen and (max-width: 820px) {
  .hero-body {
    padding: 5rem 36px 2rem;
  }

  .lets-do-it-box {
    padding: 43px 8px;
    background: linear-gradient(
        105.9deg,
        rgba(55, 125, 255, 0.15) 7.44%,
        rgba(255, 255, 255, 0) 66.31%
      ),
      #0a0228;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 480px) {
  // .top-section-hero {
  //   background-image: url("../../assets/images/homepage/Vector.png");
  //   background-position: top right;
  //   background-repeat: no-repeat;
  // }

  // .mockup-image {
  //   display: none;
  // }
  .drawer.drawer-end {
    direction: unset;
  }
  .lets-do-it-column {
    display: flex;
    flex-direction: column;
  }

  .hero-body {
    padding: 5rem 20px 2rem;
  }
}
