.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  //  padding-right: 100px;

}

.swiper-slide {
  z-index: 0;
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  z-index: 0;
  display: block;
  width: 100%;
}

.swiper-slide .swiper-slide-visible {
  z-index: -4;
  margin-right: 50px;
  transition-duration: 0ms;
  // transform: translate3d(0px, 0px, -450px) rotateX(0deg) rotateY(225deg) scale(1);
}
