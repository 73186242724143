#sidebar-desktop {
  display: block;
}

.pro-sidebar {
  height: 100vh;
  position: fixed;
}

#collapsible-sidebar {
  z-index: 99;
  width: 280px;
}
#collapsible-sidebar .pro-sidebar {
  height: 100%;
}
#collapsible-sidebar .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 99;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}
#collapsible-sidebar .pro-sidebar {
  width: 240px;
  min-width: 240px;
}
#collapsible-sidebar .pro-sidebar.collapsed {
  width: 100px;
  min-width: 100px;
}
#collapsible-sidebar .pro-sidebar-inner {
  background: #131420;
  box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.15);
}
#collapsible-sidebar .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#collapsible-sidebar .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#collapsible-sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #fff;
  margin: 10px 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

#collapsible-sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .active {
  background: linear-gradient(
      290.19deg,
      rgba(255, 255, 255, 0) 26.59%,
      #2d1582 112.22%
    ),
    #fd004e;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

#collapsible-sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: transparent;
  color: #fff;
  font-size: 22px;
  border-radius: 3px;
}

#collapsible-sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  background: linear-gradient(
      290.19deg,
      rgba(255, 255, 255, 0) 26.59%,
      #2d1582 112.22%
    ),
    #fd004e;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 9px 35px 8px 12px;
  cursor: pointer;
}

#collapsible-sidebar .pro-sidebar-inner .pro-sidebar-layout .active {
  color: #fd004e;
  border-right: 3px solid #fd004e;
}

li.active .pro-item-content {
  background: linear-gradient(
      290.19deg,
      rgba(255, 255, 255, 0) 26.59%,
      #2d1582 112.22%
    ),
    #fd004e;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

#collapsible-sidebar .logo {
  padding: 20px;
}

.outlet-large {
  width: calc(100vw - 75px);
  padding-top: 5rem;
}

.outlet-small {
  width: calc(100vw - 280px);
  padding-top: 5rem;
  margin: auto;
}
@media only screen and(max-width:1280px) {
  // #sidebar-desktop{
  //     display: none;
  //   }
  // .outlet-small {
  //   width: 100vw;
  //   padding-top: 5rem;
  // }
}

@media only screen and(max-width:890px) {
  // #sidebar-desktop{
  //     display: none;
  //   }
  .outlet-small {
    width: 100vw;
    padding-top: 5rem;
  }
}

@media only screen and(max-width:480px) {
  // #sidebar-desktop{
  //     display: none;
  //   }
  .outlet-small {
    width: 100vw;
    padding-top: 5rem;
  }
}
