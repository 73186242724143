.subscription-section {
  background-image: url("../../assets/images/subscription/pink.png");
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  z-index: -1;
}

.subscription-section section {
  margin: 0;
  max-width: 100%;
}


/* SliderButton.css */
.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  transition: transform 0.3s ease;
  background-color: #ffffff;
  width: 320px;
  justify-items: center;
  border-radius: 10px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
  font-family: "Poppins", sans-serif; /* Use Poppins font family */
  font-size: 18px; /* Adjust the base font size */
}

.slider-border {
  display: flex;
  box-shadow: 0px 4px 9px 0px #0000000d;
  justify-content: flex-end;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 10px;
  overflow: hidden;
}

.slider-button {
  display: flex;

  background: linear-gradient(
      273.47deg,
      rgba(255, 255, 255, 0) -1.96%,
      #2d1582 104.81%
    ),
    #fd004e;
  border-radius: 10px;

  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.slider-text {
  display: flex;
  transition: transform 0.3s ease;
}

.slider-option {
  flex: 1;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
}

.slide-left {
  transform: translateX(0%);
}

.slide-right {
  transform: translateX(100%);
}

.slider-container.slide-active {
  transform: translateX(-100%);
}

.slider-option-button {
  flex: 1;
 
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  transition: background 0.3s ease, color 0.3s ease;
}

.slider-option-button.selected {
  background: linear-gradient(
      273.47deg,
      rgba(255, 255, 255, 0) -1.96%,
      #2d1582 104.81%
    ),
    #fd004e;

  margin-top: auto;
  margin-bottom: auto;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.5;
}
.slider-option-button:not(.selected) {
  color: #191a15;
}
// border 
.border-gradient {


    border-image-slice: 1; /* You may adjust this value based on your preference */
  }

 
  .icon {
    color: red; /* Set the color to red */
    font-size: 18px; /* Adjust the font size to your preference */
  }
  
  
  /* Description style */
  .card-description {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  
  /* Price style */
  .card-price {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #27ae60;

  }
  
  /* Points style */
  .card-points {
    list-style-type: disc;
    padding-left: 20px;
  }

  #one {
    /* Your specific styles for the title with ID "title-1" */
    font-size: 24px;
    color: #fa0000;
    /* Add other styles as needed */
  }
  

  
  @media screen and (min-width: 1024px) {
    /* Adjustments for desktop screens */
    .slider-container {
      max-width: 800px;
      font-size: 24px; /* Adjust font size for larger desktop screens */
    }
  
    .slider-option,
    .slider-option-button {
      font-size: 20px; /* Adjust font size for larger desktop screens */
    }
  }
  @media screen and (min-width: 768px) {
    /* Adjustments for tablets and larger devices */
    .slider-container {
      max-width: 480px;
      font-size: 20px; /* Adjust font size for larger screens */
    }
  
    .slider-option,
    .slider-option-button {
      font-size: 18px; /* Adjust font size for larger screens */
    }
  }
  