.family-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 39px;
  color: #ffffff;
}

.family-subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.family-subtitle strong {
  color: #00fdee;
}
